#facebook-page-plugin {
    position: relative;
    padding-bottom: 100%;
    /* Set the aspect ratio (height/width) */
    height: 0;
    overflow: hidden;
}

#facebook-page-plugin iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.single_cardoverlay {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgba(1, 1, 1, 0.222);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: -10px -80px 100px rgba(0, 0, 0, 0.842) inset;

}
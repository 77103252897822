.newsoverlay {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgba(1, 1, 1, 0.222);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: -10px -80px 50px rgba(50, 50, 50, 0.979) inset;

}